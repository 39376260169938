.table-editable {
	td[contenteditable="true"] {
		background-color: #fffedb !important;
		outline: none;
		cursor: text;
		&:focus {
			outline: none;
		}
	}
	td[data-editable="true"]{
		cursor: pointer;
		&:hover{
			background-color:#FFFFED;
			color: #444444;
		}
		&:after{
			/*font-family: 'icomoon';*/
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			font-size: 0.6em;
			margin-right: -8px;
			margin-top: -9px;
			float:right;
			opacity: 0.2;
			filter: "alpha(opacity=20)";

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e606";
		}
	}
}
@media print{
	table.table-editable{
		td[data-editable="true"]{
			&:after{
				display:none;
			}
		}
	}
}
